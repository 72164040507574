<template>
  <div>
    <v-select
      ref="select"
      class="product-select"
      :label="label"
      :options="filtered"
      :filterable="false"
      :clearable="true"
      :value="selected || initialValue"
      @input="onInput"
      @search="queueOnSearch"
      :required="required"
      :selectable="option => !option.hasOwnProperty('group')"
    >
      <template #search="{attributes, events}">
        <input
          class="vs__search"
          :required="required && !(selected || initialValue)"
          v-bind="attributes"
          v-on="events"
        />
      </template>
      <template #no-options>
        {{ $t("dropdown.no_option") }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  data: () => ({
    selected: null,
    initialValue: null,
    query: "",
    initialLimit: 40,
    offset: 0,
    limit: 0,
    ul: null,
  }),
  mounted() {
    this.limit = this.initialLimit;
    this.ul = this.$refs.select.$el.querySelector("ul");
    this.ul.addEventListener("scroll", this.onScroll);

    this.queueOnSearch = _.debounce(this.onSearch, 500);

    this.initialValue = this.value;
  },
  computed: {
    filtered() {
      return this.options.filter(e => e && e[this.label].toLowerCase().includes(this.query));
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    queueOnSearch() {},
    clear() {
      this.selected = null;
    },
    setValue(value) {
      this.selected = value;
    },
    onSearch(query) {
      query = query.toLowerCase();

      this.query = query;
      this.$emit("search", query);
    },
    onInput(choice) {
      this.$emit("input", choice);
      this.selected = choice;
    },
    async onScroll() {
      let scrollTop = this.ul.scrollTop;
      if (this.ul.scrollTop + this.ul.offsetHeight > this.ul.scrollHeight) {
        this.limit += 10;
        await this.$nextTick();
        this.ul.scrollTop = scrollTop;
      }
    },
  },
  watch: {
    value(newValue, oldValue) {
      this.initialValue = newValue;
      this.selected = newValue;
    },
    selected(newValue, oldValue) {
      if (!newValue) this.initialValue = null;

      if (this.options.length == 1) this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss">
  .pagination {
    display: flex;
    margin: .25rem .25rem 0;
  }
  .pagination button {
    flex-grow: 1;
  }
  .pagination button:hover {
    cursor: pointer;
  }
</style>
