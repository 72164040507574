<template>
  <div>
    <!-- Machine type -->
    <h4 class="subtitle-form">{{ $t("machines.fields.machine_type") }}</h4>
    <Dropdown
      ref="dropdown"
      class="shape-custom-select mb-2"
      label="designation"
      :options="machinesTypes"
      :value="this.machineTypeSelected"
      @input="selectedInput => checkMachineType(selectedInput)"
      required
    />

    <!-- Model  && AntiDrift -->

    <b-row>
      <b-col cols="7">
        <h4 class="subtitle-form">{{ $t("machines.fields.model") }}</h4>
      </b-col>
      <b-col v-if="this.machineTypeSelected.antiDriftOption" cols="5">
        <h4 class="subtitle-form">{{ $t("machines.fields.anti_drift") }}</h4>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="7">
        <b-input type="text" id="model" v-model="modelSelected" size="sm" required />
      </b-col>
      <b-col v-if="this.machineTypeSelected.antiDriftOption" cols="5">
        <b-form-checkbox id="antiDrift" v-model="antiDriftCheck"></b-form-checkbox>
      </b-col>
    </b-row>  

    <!-- Width -->
    <b-row>
      <b-col cols="7">
        <h4 class="subtitle-form">{{ $t("machines.fields.size") }}</h4>
      </b-col>
      <b-col cols="5">
        <h4 class="subtitle-form">{{ $t("machines.fields.unit") }}</h4>
      </b-col>
    </b-row>
    
    <b-row class="mb-2">
      <b-col cols="7">
        <NumericInput
                id="model"
                inputRef="input"
                :value="parseFloat(widthSelected)"
                @input="i => {widthSelected = i}"
                :numberOfDigits="2"
          />
      </b-col>
      <b-col cols="5">
        <b-select size="sm" :options="units" v-model="unitSelected" required></b-select>
      </b-col>
    </b-row>

    <!-- Motorized -->
    <h4 class="subtitle-form">{{ $t("machines.fields.motorized_machine") }}</h4>
    <div class="custom-control custom-switch mb-2">
      <input
        type="checkbox"
        class="custom-control-input"
        id="motorized"
        v-model="motorizedSelected"
      />
      <label class="custom-control-label" for="motorized"></label>
    </div>

    <!-- Tracker -->
    <div v-if="motorizedSelected === true">
      <h4 class="subtitle-form">Tracker</h4>
      <Dropdown
        ref="dropdown"
        class="shape-custom-select"
        label="serialNumber"
        :options="trackersavailable"
        :value="this.trackerSelected"
        @input="selectedInput => trackerSelected=selectedInput"
        :required="false"
      />
    </div>

    <!-- Beacon -->
    <div v-else>
      <h4 class="subtitle-form">{{ $t("machines.fields.beacon") }}</h4>
      <Dropdown
        ref="dropdown"
        class="shape-custom-select"
        label="name"
        :options="beaconsavailable"
        :value="this.beaconSelected"
        @input="selectedInput => beaconSelected=selectedInput"
        :required="false"
      />
    </div>
    <hr>
    <!-- ask for tool -->
    <b-row class="mb-2" v-if="motorizedSelected">
      <b-col cols="1" v-b-tooltip.hover :title="$t('machines.details.askForToolHelp')">
        <b-form-checkbox v-model="askForTool"></b-form-checkbox>
      </b-col>
      <b-col cols="11">
        <h4 class="subtitle-form">{{ $t("machines.fields.askForTool") }}</h4>
      </b-col>
    </b-row>
    <!-- don't ask for inputs -->
    <b-row class="mb-2" v-if="currentActivityType.needInputs">
      <b-col cols="1" v-b-tooltip.hover :title="$t('machines.details.dontAskForInputsHelp')">
        <b-form-checkbox v-model="dontAskForInputs"></b-form-checkbox>
      </b-col>
      <b-col cols="11">
        <h4 class="subtitle-form">{{ $t("machines.fields.dontAskForInputs") }}</h4>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/machines/details/Dropdown.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { i18n } from '../../../i18n';
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin], 
  name: "machine-edit",
  created() {
    this.init();
  },
  data() {
    return {
      machineTypeSelected: {},
      antiDriftCheck: false,
      modelSelected: "",
      widthSelected: "",
      trackerSelected: {},
      beaconSelected: {},
      motorizedSelected: false,
      askForTool: false,
      dontAskForInputs: false,
      machinesTypes: [],
      index: '',
      units: [
        { value: "m", text: i18n.t("unitDenomination.unit_m") },
        { value: "rg", text: i18n.t("unitDenomination.unit_rg") },
      ],
      unitSelected: ""
    };
  },
  components: {
    Dropdown,
    NumericInput
  },
  methods: {
    /**
    * Description: Initialization of machine types.
    */
    async init() {
      this.machinesTypes = JSON.parse(JSON.stringify(await this.$store.dispatch("getAllMachineTypes")));
      if (this.$route.name === "machines.edit") {
        await this.$store.dispatch("machine/getBeaconsAvailable", { machineId: this.$route.params.machines_id });
        await this.$store.dispatch("machine/getTrackersAvailable", { machineId: this.$route.params.machines_id });
        this.index = this.machinesTypes.findIndex(element => element.id == this.currentEntry.machineType)
        if(this.index != -1) this.machineTypeSelected = this.machinesTypes[this.index];
        this.modelSelected = this.currentEntry.model;
        this.widthSelected = this.currentEntry.width;

        this.index = this.trackersavailable.findIndex(element => element.id == this.currentEntry.tracker)
        if(this.index != -1) this.trackerSelected = this.trackersavailable[this.index];

        this.index = this.beaconsavailable.findIndex(element => element.id == this.currentEntry.beacon)
        if(this.index != -1) this.beaconSelected = this.beaconsavailable[this.index];
        this.motorizedSelected = this.currentEntry.motorized;
        this.unitSelected = this.currentEntry.widthUnit;
        this.askForTool = this.currentEntry.askForTool;
        this.dontAskForInputs = this.currentEntry.dontAskForInputs;
        this.antiDriftCheck = this.currentEntry.antiDrift;
      }
      else if (this.$route.name === "machines.new") {
        this.unitSelected = this.units[0].value;
        this.$store.dispatch("machine/getBeacons");
        this.$store.dispatch("machine/getTrackers");
      }
    },
    /**
     * Description: Return machine informations.
     */
    getMachineInfos() {
      let machineInfos = {
        machineType: this.machineTypeSelected,
        model: this.modelSelected,
        width: this.widthSelected != "" ? this.widthSelected : null,
        tracker: this.trackerSelected != "" ? this.trackerSelected : null,
        beacon: this.beaconSelected != "" ? this.beaconSelected : null,
        motorized: this.motorizedSelected,
        antiDrift: this.antiDriftCheck,
      };

      return machineInfos;
    },
    /**
     * Description: Fill in the checkbox "Motorized machine" according to the attribute motorized of the machine type.
     */
    checkMachineType(value) {
      this.machineTypeSelected=value;
      if(value)
      {
        if(value.motorized != null) this.motorizedSelected = value.motorized;
        else this.motorizedSelected = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      currentMachine: "machine/currentEntry",
      getMachineFilters: "machine/getMachineFilters",
      trackersavailable: "machine/trackersavailable",
      beaconsavailable: "machine/beaconsavailable",
      currentActivityType: "currentActivityType",
    }),
  },
  watch: {
    modelSelected: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {model: this.modelSelected,});
      }
    },
    widthSelected: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {width: this.widthSelected,});
      }
    },
    motorizedSelected: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {motorized: this.motorizedSelected,});
      }
    },
    machineTypeSelected: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {machineType: this.machineTypeSelected.id,});
        await this.$store.dispatch("getActivityTypeFromMachineTypes", [this.machineTypeSelected.id]);
      }
    },
    trackerSelected: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {tracker: this.trackerSelected ? this.trackerSelected.id : null,});
      }  
    },
    beaconSelected: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {beacon: this.beaconSelected ? this.beaconSelected.id : null,});
      }       
    },
    unitSelected: {
      handler: async function () {
        await this.$store.dispatch(this.storeEditAction, { widthUnit: this.unitSelected });
      },
    },
    askForTool: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {askForTool: this.askForTool });
      }
    },
    dontAskForInputs: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {dontAskForInputs: this.dontAskForInputs});
      }
    },
    antiDriftCheck: {
      handler: async function(){
        await this.$store.dispatch(this.storeEditAction, {antiDrift: this.antiDriftCheck});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
